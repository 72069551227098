import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';
import { FlexibleRewardConfig } from './getFlexibleRewardConfig';

interface Params {
  checkout?: Checkout;
  userPointsAmount: number;
  flexibleRewardConfig: FlexibleRewardConfig;
}

export function calculateMaxFlexibleRewardPoints({ checkout, userPointsAmount, flexibleRewardConfig }: Params): number {
  const { costInPoints, discountPerPoint } = flexibleRewardConfig;
  const orderTotalAmount = parseFloat(checkout?.totalAfterGiftCard?.amount ?? '0');
  const shippingAmount = parseFloat(checkout?.priceSummary?.shipping?.amount ?? '0');
  const taxAmount = checkout?.taxIncludedInPrice ? 0 : parseFloat(checkout?.priceSummary?.tax?.amount ?? '0');

  const discountableAmount = Math.max(orderTotalAmount - shippingAmount - taxAmount, 0);
  const maxValueForOrder = discountPerPoint ? Math.ceil(discountableAmount / discountPerPoint) : 0;

  return Math.min(userPointsAmount, Math.max(maxValueForOrder, costInPoints));
}
